import React from 'react'

import SRModal from "../modal/modal"

const TermsOfSales = ({ text }) => (
    <SRModal title={"Conditions Générales de Ventes"} text={"Conditions Générales de Ventes"}>
        <div className={"content content-modal"}>
            <p>Toute inscription, pour être prise en compte, doit être accompagnée de son règlement.</p>
            <p>Changement de nom :<br />
            Jusqu’au vendredi 30 Septembre 2022, aucun frais.<br />
                À partir du samedi 1easeas octobre 2022 : 75 € TTC pour frais de dossier et ajustement au tarif en vigueur à la date du changement de nom (ajouter les justificatifs demandés).</p>
            <p>Conditions d’annulation : Seules les annulations parvenant à Carco par écrit (courrier, fax ou mail) seront prises en compte.<br />
                Frais d’annulation :<br />
                À partir du mercredi 15 juin 2022 : 75 € TTC de frais de dossier.<br />
                À partir du vendredi 2 octobre 2022 :  pénalité de 100 % du montant de l’inscription.</p>
            <p>En cas d’annulation pour cause de grève(s), conditions climatiques, cas de force majeure ou de tout incident indépendant de la volonté du Congrès Français de Psychiatrie, les frais d’annulation seront appliqués.</p>
            <p>L’inscription au congrès entraîne l’acceptation totale de ces conditions.</p>
        </div>
    </SRModal>
)

export default TermsOfSales
