import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

import Header from "../header/header"
import Title from "../title/title"
import Footer from "../footer/footer"

const Main = ({ children }) => {
    return (
        <>
            <Header/>
            <Title/>
            <div className={"sr-main"}>{children}</div>
        </>
    )
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Main
