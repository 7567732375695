import React from "react"
import {Link} from "gatsby"
import "./title.scss"

const Title = () => (
    <>
        <Link to={"/login"} className={"btn btn-info btn-round btn-sm mx-auto mt-2"}>Déja inscrit ? Connectez-vous</Link>
        <div className={"sr-main-title"}>
            <p className={"title"}>Bienvenue<br /><strong>Choisissez votre inscription</strong></p>
        </div>
    </>
)

export default Title
