import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import "../css/main.scss"
import Main from "./main/main"

const Layout = ({ children, main, className }) => {
  return (
    <div className={"container layout " + className}>
        <div className={"sr-card"}>
            {main ? <Main>{children}</Main> : children}
        </div>
    </div>
  )
}

Layout.propTypes = {
    children:   PropTypes.node.isRequired,
    main :      PropTypes.bool,
    className:  PropTypes.string,
}

Layout.defaultProps = {
    main: true,
    className: "",
}

export default Layout
